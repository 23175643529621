import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Room } from 'components'
import { classMaker } from 'tools'
import './floor.scss'

class Floor extends Component {
    static propTypes = {
        number: PropTypes.number,
        rooms:  PropTypes.number,
        
        saved: PropTypes.object,
        date:  PropTypes.object,
        last:  PropTypes.bool, 

        onDie:  PropTypes.func,
        onTrip: PropTypes.func
    }
    
    constructor ( props ) {
        super( props )
        
        this.rrefs = Array( props.rooms )
        this.rooms = this.generate( props )
    }

    componentWillReceiveProps ( nextProps ) {
        const
            { date } = this.props

        ;( date !== nextProps.date ) && ( this.rebuild( nextProps ) )
    }

    rebuild = props => this.rooms = this.generate( props )

    generate = props =>
        (
            props.saved
                ? this.load( props )
                : this.scratch( props )
        )

    load = props =>
        {
            const
                { rooms, number, date, saved } = props,
                res = []

            for ( let r = 1; r <= rooms; r++ ) {
                const
                    num = ( number - 1 ) * rooms + r

                this.rrefs[r - 1] = this.rrefs[r - 1] || React.createRef()

                res.push( 
                    <Room
                        key    = { num }
                        number = { num }
                        floor  = { number }
                        first  = { r === 1 }
                        last   = { r === rooms }
                        ref    = { this.rrefs[r - 1] }
                        date   = { date }
                        saved  = { saved.rooms[r - 1] }
                        onDie  = { this.props.onDie }
                        onTrip = { this.props.onTrip }
                    />
                )
            }

            return res
        }

    scratch = props =>
        {
            const
                { rooms, number, date } = props,
                res = []

            for ( let r = 1; r <= rooms; r++ ) {
                const
                    num = ( number - 1 ) * rooms + r

                this.rrefs[r - 1] = this.rrefs[r - 1] || React.createRef()

                res.push( 
                    <Room
                        key    = { num }
                        number = { num }
                        floor  = { number }
                        first  = { r === 1 }
                        last   = { r === rooms }
                        ref    = { this.rrefs[r - 1] }
                        date   = { date }
                        onDie  = { this.props.onDie }
                        onTrip = { this.props.onTrip }
                    />
                )
            }

            return res
        }

    max = () => this.rooms.reduce( ( total, room ) => total + room.ref.current.max(), 0 )
    update = aura => this.rooms.forEach( room => room.ref.current && room.ref.current.update( aura ) )
    aura = () => this.rooms.reduce( ( summ, room ) => summ + room.ref.current.getAura(), 0 ) / this.rooms.length
    realAura = () => this.rooms.reduce( ( summ, room ) => summ + room.ref.current.realAura(), 0 ) / this.rooms.length
    count = () => this.rooms.reduce( ( summ, room ) => summ + room.ref.current.count(), 0 )
    death = room => this.rooms.forEach( r => r.ref.current.death( room ) )

    balance = () => 
        this.rooms.reduce( 
            ( summ, room ) => 
                (
                    room.ref.current
                        ? summ + room.ref.current.balance()
                        : summ
                ), 
                0
        )

    render () {
        const
            { number, last } = this.props,
            cls = new classMaker( 'floor' )

        cls.toggle( number === 1, 'floor-first' )
        cls.toggle( last, 'floor-last' )

        return (
            <div className={cls.get()}>
                { this.rooms }
            </div>
        )
    }
}

export default Floor
