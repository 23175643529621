/* VENDOR */
import React, { Component } from 'react'

/* APPLICATION */
import { classMaker } from 'tools'
import './center.scss'

class Center extends Component {
    render () {
        const
            cls = new classMaker( 'center-container' )

        cls.add( this.props.className )

        return (
            <div className={cls.get()}>
                { this.props.children }
            </div>
        )
    }
}

export default Center
