import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { classMaker } from 'tools'
import './dialog.scss'

class Dialog extends Component {
    static propTypes = {
        header: PropTypes.object,
        footer: PropTypes.object,
        headerBG: PropTypes.string
    }

    render () {
        const
            { footer, header, headerBG } = this.props,
            cls = new classMaker( 'dialog' )

        cls.add( this.props.className )

        return (
            <div className={cls.get()}>
                {
                    header && (
                        <div className="dialog-header" style={{ backgroundColor: headerBG }}>
                            { header }
                        </div>
                    )
                }
                <div className="dialog-content">
                    { this.props.children }
                </div>
                {
                    footer && (
                        <div className="dialog-footer">
                            { footer }
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Dialog
