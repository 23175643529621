import * as c from './consts'

export const breakpoints = ( chance, string ) =>
    {
        const
            res = {},
            keys = string
                ? Object.keys( chance ).reverse()
                : Object.keys( chance )

        let
            summ = 100

        keys
            .map( key => string ? key : parseInt( key ) )
            .sort( ( a, b ) => string ? 0 : b - a  )
            .forEach( key => {
                res[key] = summ - chance[key]
                summ -= chance[key]
            })

        return res
    }

export const max = arr => arr.reduce( ( max, num ) => parseInt( num ) > max ? parseInt( num ) : max, -Infinity )
export const min = arr => arr.reduce( ( min, num ) => parseInt( num ) < min ? parseInt( num ) : min, Infinity )

export const key = ( obj, search, filter ) =>
    (
        Object.keys( obj )
            .filter( key => (
                Array.isArray( filter )
                    ? filter.map( f => key.indexOf( f ) > -1 ).includes( true )
                    : key.indexOf( filter ) > -1
             ))
            .find( key => obj[key] === search ) + ' (' + search + ')'
    )

export const hours = date => c[ 'CH_' + ( parseInt( date.format( 'H' ) ) % 12 ) ]

export const minutes = date =>
    {
        const
            minutes = parseInt( date.format( 'm' ) )

        return c[ 'CM_' + ( minutes - minutes % 5 ) ]
    }