import * as c from './consts'

/* PERCENTAGE */
/* Summary should be 100 */

//export const light = [ '#dba146', '#e3b178', '#e8b796', '#d18a8d', '#cc8456', '#d6bd96', '#a1cccf', '#a9b9d1', '#bd99ac', '#95bbcc' ]
export const light = 
    [ 
        '#fd8573', 
        '#99c5e5', 
        '#fbb590', 
        '#f1bb78', 
        '#f69054', 
        '#dfbbc7', 
        '#a3d1f8', 
        '#ddc0be', 
        '#feb57b', 
        '#aac9f6', 
        '#fbc2cd', 
        '#fbbf74', 
        '#ffd192', 
        '#f2c8a2' 
    ]

export const decor = 
    ({
        [c.DECOR_NONE]:  50,
        [c.DECOR_OTHER]: 50
    })

export const template =
    ({
        [c.TMPL_STUDENTS]: 10,
        [c.TMPL_FAMILY]:   20,
        [c.TMPL_PAIR]:     40,
        [c.TMPL_SINGLE]:   30
    })

export const age = 
    ({
        [c.AGE_YOUNG]:  20,
        [c.AGE_MIDDLE]: 40,
        [c.AGE_UPPER]:  35,
        [c.AGE_ELDER]:  5
    })

export const gender =
    ({
        [c.GEN_MALE]: 46,
        [c.GEN_FEMALE]: 54
    })

export const orientation =
    ({
        [c.ORIENT_HETERO]: 75,
        [c.ORIENT_HOMO]:   15,
        [c.ORIENT_BI]:     10
    })

export const forientation =
    ({
        [c.ORIENT_HETERO]: 50,
        [c.ORIENT_HOMO]:   20,
        [c.ORIENT_BI]:     30
    })

export const chrono =
    ({
        [c.CHRONO_OWL]: 50,
        [c.CHRONO_LARK]: 30,
        [c.CHRONO_WOODPECKER]: 15,
        [c.CHRONO_SLOTH]: 5
    })

export const work =
    ({
        [c.WORK_NONE]: 15,
        [c.WORK_STANDART]: 30,
        [c.WORK_NIGHT]: 15,
        [c.WORK_12]: 20,
        [c.WORK_NOCTIDIAL]: 10,
        [c.WORK_WATCH]: 10
    })

export const fwork =
    ({
        [c.WORK_NONE]: 30,
        [c.WORK_STANDART]: 50,
        [c.WORK_12]: 20
    })

export const pension =
    ({
        [c.WORK_NONE]: 85,
        [c.WORK_STANDART]: 15
    })

export const workaholic =
    ({
        0: 50,
        1: 50
    })

export const drink =
    ({
        [c.AGE_YOUNG]: {
            0: 50,
            1: 50
        },
        [c.AGE_MIDDLE]: {
            0: 40,
            1: 60
        },
        [c.AGE_UPPER]: {
            0: 20,
            1: 80
        },
        [c.AGE_ELDER]: {
            0: 70,
            1: 30
        }
    })

export const fdrink =
    ({
        [c.AGE_YOUNG]: {
            0: 60,
            1: 40
        },
        [c.AGE_MIDDLE]: {
            0: 50,
            1: 50
        },
        [c.AGE_UPPER]: {
            0: 40,
            1: 60
        },
        [c.AGE_ELDER]: {
            0: 90,
            1: 10
        }
    })

export const smoke =
    ({
        [c.AGE_YOUNG]: {
            0: 80,
            1: 20
        },
        [c.AGE_MIDDLE]: {
            0: 50,
            1: 50
        },
        [c.AGE_UPPER]: {
            0: 40,
            1: 60
        },
        [c.AGE_ELDER]: {
            0: 80,
            1: 20
        }
    })

export const fsmoke =
    ({
        [c.AGE_YOUNG]: {
            0: 80,
            1: 20
        },
        [c.AGE_MIDDLE]: {
            0: 70,
            1: 30
        },
        [c.AGE_UPPER]: {
            0: 90,
            1: 10
        },
        [c.AGE_ELDER]: {
            0: 80,
            1: 20
        }
    })

export const hobby =
    ({
        [c.HOBBY_STROLL]: 5,
        [c.HOBBY_TV    ]: 50,
        [c.HOBBY_GAMES ]: 25,
        [c.HOBBY_READ  ]: 10,
        [c.HOBBY_CRAFT ]: 10
    })

export const hobbyCount =
    ({
        1: 50,
        2: 35,
        3: 10,
        4: 5
    })

export const aura =
    ({
         2: 25,
         1: 50,
         0: 25
    })

export const students = 
    ({
        0: 20, //1 
        1: 50, //2
        2: 15, //3
        3: 10, //4
        4: 5   //5
    })

export const balance =
    ({
        0: 0,
        1: 0,
        2: 5,
        3: 5,
        4: 40,
        5: 40,
        6: 5,
        7: 5,
        8: 0,
        9: 0
    })