/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { Person, Bitmap, Center } from 'components'
import { classMaker, generate, consts, chance } from 'tools'
import './room.scss'

class Room extends Component {
    static propTypes = {
        number: PropTypes.number,
        floor:  PropTypes.number,

        first: PropTypes.bool,
        last:  PropTypes.bool,

        onDie:  PropTypes.func,
        onTrip: PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.prefs    = []
        
        this.people   = props.saved ? generate.load( this, props.saved ) : []
        this.balval   = null
        this.previous = null
        this.wasClick = false

        this.load( props, 'aura', 0 )
        this.load( props, 'light', false )
        this.load( props, 'bgcolor', generate.anyOf( chance.light ) )
        this.load( props, 'window', generate.rrandom( consts.COUNT_WINDOWS - 1 ) + 1 )
        this.load( props, 'curtains', generate.rrandom( consts.COUNT_CURTAINS ) )
        this.load( props, 'decor', generate.popular( chance.decor, consts.DECOR_NONE, consts.COUNT_DECOR ) )
    }

    load = ( props, key, def ) =>
        (
            this[key] = props.saved
                ? props.saved[key]
                : def
        )

    on  = () => this.light = true
    off = () => this.light = false

    toggle = () => {
        this.light = !this.light
        this.wasClick = true
    }
    
    setAura = append => this.aura = Math.round( Math.max( -1, Math.min( 1, this.aura + append ) ) * 1000000 ) / 1000000
    getAura = () => ( this.people.length > 0 ? this.aura : 0 )
    realAura = () => this.aura
    count = () => this.people.length

    persons = () => 
        this.people.map(
            ( data, i ) => {
                const
                    { date } = this.props

                this.prefs[i] = this.prefs[i] || React.createRef()

                return (
                    <Person
                        key  = { i }
                        ref  = { this.prefs[i] }

                        data  = { data }
                        date  = { date }
                        aura  = { this.aura }
                        light = { this.light }

                        onAura = { this.setAura }
                    />
                )
            }
        )

    max = () => this.people.length * consts.MAX_BALANCE

    death = room =>
        {
            if ( room.props.number === this.props.number ) {
                this.setAura( consts.VAL_DEATH * 2 )
                return
            } else if ( room.props.floor === this.props.floor ) {
                this.setAura( consts.VAL_DEATH )
                return
            }

            const
                affect = ( 1 / ( Math.abs( this.props.floor - room.props.floor ) + 1 ) ) * consts.VAL_DEATH

            this.setAura( affect )
        }

    die = index =>
        {
            this.remove( index )
            this.people.forEach( p => p.balance -= 2 / p.persist )
            this.props.onDie( this )
        }

    travel = index =>
        {
            this.remove( index )
            this.people.forEach( p => {
                const
                    imp = ( generate.rrandom( 1000 ) - 500 ) / 100 

                p.balance += imp / p.persist 
            })
            this.props.onTrip( this )
        }

    remove = index => 
        {
            this.people.splice( index, 1 )
            this.prefs.splice( index, 1 )
        }

    dying     = status => [ consts.ST_REMOVE_READYTODIE, consts.ST_REMOVE_SUICIDE, consts.ST_REMOVE_DEAD ].indexOf( status ) > -1
    adventure = status => [ consts.ST_REMOVE_GOTOTRAVEL, consts.ST_REMOVE_GOAWAY, consts.ST_REMOVE_TRAVEL ].indexOf( status ) > -1
    sleeping  = status => [ consts.ST_IDLE_SLEEP, consts.ST_IDLE_WAKEUP, consts.ST_IDLE_GOTOSLEEP ].indexOf( status ) > -1

    update  = aura => 
        {
            if ( this.people.length > 0 ) {
                ;( !this.curtains ) && ( this.curtains = generate.rrandom( consts.COUNT_CURTAINS ) )
                ;( !this.decor ) && ( this.decor = generate.rrandom( consts.COUNT_DECOR ) )

                this.people.forEach( ( person, index ) => {
                    const
                        pers = this.prefs[index].current

                    pers.update( this.wasClick )

                    ;( person.status === consts.ST_REMOVE_DEAD ) && ( this.die( index ) )
                    ;( person.status === consts.ST_REMOVE_TRAVEL ) && ( this.travel( index ) )

                    if ( person.balance < -person.limit && !this.dying( person.status ) && !this.sleeping( person.status ) ) {
                        person.status = consts.ST_REMOVE_READYTODIE
                    }

                    if ( person.balance > person.limit && !this.adventure( person.status ) && !this.sleeping( person.status ) ) {
                        person.status = consts.ST_REMOVE_GOTOTRAVEL
                    }
                })

                this.previous = this.balval
                this.balval = this.balance()
            } else {
                const
                    buy = generate.rrandom( 1000 ),
                    limit = 750 - this.aura * 250 

                if ( buy > limit ) {
                    this.people = generate.persons( this )
                    this.on()
                }

                this.setAura( -consts.VAL_AURA )
                this.curtains = 0
                this.decor = 0
            }

            ;( this.people.length > 0 )
                ? this.setAura( aura / 1000 )
                : this.setAura( Math.max( 0, aura ) / 10 )

            this.wasClick = false
        }

    balance = () => 
        (
            this.people.reduce( 
                ( summ, person, index ) => 
                    (
                        this.prefs[index]
                            ? summ + this.prefs[index].current.balance()
                            : summ
                    ), 
                0
            )
        )

    render () {
        const
            { number, first, last } = this.props,
            cls  = new classMaker( 'room' ),
            acls = new classMaker( 'room-aura' ),
            wcls = new classMaker( 'window-layer' ),
            dcls = new classMaker( 'decor-layer' ),
            ccls = new classMaker( 'curtains-layer' ),
            opacity = Math.abs( this.aura ),
            balanceIcon = 
                (
                    this.balval > 0
                        ? ( this.balval > this.previous ? '↟' : '↡' )
                        : ( this.balval > this.previous ? '↑' : '↓' )
                )

        cls.addIf( this.light, 'light-on', 'light-off' )
        cls.toggle( first, 'room-first' )
        cls.toggle( last, 'room-last' )

        acls.addIf( this.aura > 0, 'good', 'bad' )
        wcls.add( 'type-' + this.window )
        dcls.add( 'type-' + this.decor )
        ccls.add( 'type-' + this.curtains )

        return (
            <div 
                className = { cls.get() } 
                onClick   = { this.toggle } 
                title     = { "Aura: " + this.aura }
            >
                <div className="light-layer" style={{ backgroundColor: this.bgcolor }}></div>
                <div className="room-number">
                    <Center>
                        <Bitmap text={ number.toString() } />
                    </Center>
                </div>
                <div className="room-balance">
                    <Bitmap text={ balanceIcon } title={this.balval} />
                </div>
                {/*({ Math.round( this.balance() * 1000 ) / 1000 })*/}
                {/*<span style={{ float: 'right' }}>
                    { Math.round( aura * 1000 ) / 1000 }
                </span>*/}
                <div className="persons-layer">
                    <Center>
                        { 
                            this.people.length > 0
                                ? this.persons()
                                : 'Sale!'
                        }
                    </Center>
                </div>
                <div className={dcls.get()}></div>
                <div className={ccls.get()}></div>
                <div className={wcls.get()}></div>
                <div className="wall-layer"></div>
                <div className={acls.get()} style={{ opacity }}></div>
                { 
                    this.aura < 0 && (
                        <div className="desaturate" style={{ opacity }}></div>
                    )
                }
            </div>
        )
    }
}

export default Room
