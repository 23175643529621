import moment from 'moment'

import * as c         from './consts'
import * as extract   from './extract'
import duration       from './duration'

import { anyOf, rrandom } from './generate'

const is = ( data, status ) => 
    (
        Array.isArray( status )
            ? status.indexOf( data.status ) > -1
            : data.status === status
    )

export const life = data =>
    {
        const
            val_hunger = is( data, c.ST_IDLE_SLEEP )
                ? c.VAL_HUNGER / 10
                : c.VAL_HUNGER 

        data.hunger = is( data, c.ST_IDLE_EAT )
            ? Math.max( 0, data.hunger - val_hunger * 5 )
            : Math.min( 100, data.hunger + val_hunger )

        data.energy = is( data, c.ST_IDLE_SLEEP )
            ? Math.min( 100, data.energy + c.VAL_ENERGY * 2 )
            : Math.max( 0, data.energy - c.VAL_ENERGY )

        ;( is( data, c.ST_IDLE_HOUSING ) )
            ? data._room.setAura( c.VAL_AURA * 5 )
            : (
                data.group === c.ST_AWAY 
                    ? data._room.setAura( -c.VAL_AURA / 2 )
                    : data._room.setAura( -c.VAL_AURA )
            )

        ;( is ( data, c.ST_IDLE_EAT ) ) && ( data.health += .001 )
        ;( is( data, [ c.ST_IDLE_SMOKE, c.ST_IDLE_DRINK ] ) ) && ( data.health -= .001 )
    }

export const balance = ( data, aura ) =>
    {
        let
            balance = data.balance

        balance -= data.hunger / 10 / data.persist
        balance += data.energy / 10 / data.persist
        balance += ( data.health - 50 ) / 10 / data.persist

        if ( data.group !== c.ST_AWAY ) {
            balance += aura > 0 ? aura / data.persist : aura * 10 / data.persist
        }

        if ( data.workaholic ) {
            balance = ( data.group === c.ST_AWAY )
                ? balance + 5 / data.persist
                : balance - 5 / data.persist
        }

        switch ( data.status ) {
            case c.ST_IDLE_HOUSING:
                balance -= 5 / data.persist
                break
            case c.ST_IDLE_PROCRASTINATE:
                balance -= 10 / data.persist
                break
            case c.ST_IDLE_HOBBY_STROLL:
            case c.ST_IDLE_HOBBY_TV:
            case c.ST_IDLE_HOBBY_GAMES:
            case c.ST_IDLE_HOBBY_READ:
            case c.ST_IDLE_HOBBY_CRAFT:
                balance += 10 / data.persist
                break
            case c.ST_IDLE_SMOKE:
                balance += 3 / data.persist
                break
            case c.ST_IDLE_DRINK:
                balance += balance / data.persist
                break
            case c.ST_IDLE_SLEEP:
            case c.ST_IDLE_EAT:
                balance += 1 / data.persist
                break
            case c.ST_WORK_ATWORK:
                if ( [ c.WORK_NIGHT, c.WORK_NOCTIDIAL, c.WORK_WATCH ].indexOf( data.work ) > -1 ) {
                    balance -= 5 / data.persist
                }
                break
            default:
                break
        }
    
        data.balance = Math.round( Math.max( -c.MAX_BALANCE, Math.min( c.MAX_BALANCE, balance ) ) * 10000 ) / 10000
    }

export const status = ( data, date, light, toggled ) =>
    {
        if ( toggled ) {
            data.status = next( data, date, light, toggled )
            updateHistory( data, date )
            ;( data.status === c.ST_IDLE_HOUSING ) && ( data._room.on() )
            return
        }

        if ( data.group === c.ST_IDLE && !needWakeUp( data, date ) && !sleeping( data ) && data.energy < c.LIM_ENERGY && data.hunger < c.LIM_HUNGER ) {
            data.status = c.ST_IDLE_GOTOSLEEP
            updateHistory( data, date )
            data._room.off()
            return
        }

        if ( data.group === c.ST_AWAY ) {
            if ( endOfWorkDay( data, date ) && data.status !== c.ST_WORK_GOHOME ) {
                data.status = c.ST_WORK_GOHOME
                updateHistory( data, date )
                return
            }
        }

        if ( durationPass( data, date, data.status ) ) {
            const
                previous = extract.key( c, data.status, ['ST_IDLE_', 'ST_WORK_'] )

            data.status = next( data, date, light )

            updateHistory( data, date )
            ;( data.status === c.ST_IDLE_HOUSING ) && ( data._room.on() )
        }
    }

const sleeping = data => [ c.ST_IDLE_SLEEP, c.ST_IDLE_GOTOSLEEP ].indexOf( parseInt( data.status ) ) > -1

const next = ( data, date, light, toggled ) =>
    {
        switch ( data.status ) {

            case c.ST_IDLE_SLEEP:
                if ( data.hunger > c.LIM_HUNGER ) return c.ST_IDLE_WAKEUP
                if ( data.energy >= c.LIM_ENERGY && light ) return c.ST_IDLE_WAKEUP
                if ( needWakeUp( data, date, light ) && data.energy >= c.TOP_ENERGY ) return c.ST_IDLE_WAKEUP
                return c.ST_IDLE_SLEEP

            case c.ST_IDLE_WAKEUP:
                data._room.on()
                return morning( data )

            case c.ST_IDLE_SMOKE:
                if ( !historyHas( data, c.ST_IDLE_EAT ) ) return c.ST_IDLE_EAT
                
                if ( workWake( data, date ) ) {
                    return c.ST_IDLE_PREPARING
                }

                if ( data._room.aura > .5 ) {
                    return data.balance > 0
                        ? c.ST_IDLE_HOBBY
                        : anyOf([ c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                }

                return data.balance > 0
                    ? anyOf([ c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY ])
                    : anyOf([ c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])

            case c.ST_IDLE_DRINK:

                if ( data._room.aura > .5 ) {
                    return data.smoke
                        ? anyOf([ c.ST_IDLE_SMOKE, c.ST_IDLE_DRINK, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                        : anyOf([ c.ST_IDLE_DRINK, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                }

                return data.smoke
                    ? anyOf([ c.ST_IDLE_SMOKE, c.ST_IDLE_DRINK, c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                    : anyOf([ c.ST_IDLE_DRINK, c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])

            case c.ST_IDLE_GOTOSLEEP:
                return c.ST_IDLE_SLEEP

            case c.ST_IDLE_EAT:
                if ( data.hunger > 0 ) return c.ST_IDLE_EAT
                if ( data.smoke && !historyHas( data, c.ST_IDLE_SMOKE ) ) return c.ST_IDLE_SMOKE
                if ( data.drink && data.balance < 0 ) return c.ST_IDLE_DRINK
                
                if ( workWake( data, date ) ) {
                    return c.ST_IDLE_PREPARING
                }

                if ( data._room.aura > .5 ) {
                    return data.balance > 0
                        ? c.ST_IDLE_HOBBY
                        : anyOf([ c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                }

                return data.balance > 0
                    ? anyOf([ c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY ])
                    : anyOf([ c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])

            case c.ST_IDLE_HOBBY:
                return hobby( data )

            case c.ST_IDLE_HOUSING:
                if ( data._room.aura < 0 && !toggled ) return c.ST_IDLE_HOUSING
            case c.ST_IDLE_HOBBY_STROLL:
            case c.ST_IDLE_HOBBY_TV:
            case c.ST_IDLE_HOBBY_GAMES:
            case c.ST_IDLE_HOBBY_READ:
            case c.ST_IDLE_HOBBY_CRAFT:
            case c.ST_IDLE_PROCRASTINATE:
                if ( data.hunger > c.LIM_HUNGER ) return c.ST_IDLE_EAT

                if ( data.energy < c.LIM_ENERGY ) {
                    data._room.off()
                    return c.ST_IDLE_GOTOSLEEP
                }

                if ( data.drink && data.balance < 0 ) {
                    return data.smoke
                        ? anyOf([ c.ST_IDLE_DRINK, c.ST_IDLE_SMOKE, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                        : anyOf([ c.ST_IDLE_DRINK, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                }

                if ( data._room.aura > .5 ) {
                    return data.smoke
                        ? anyOf([ c.ST_IDLE_SMOKE, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                        : anyOf([ c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                }

                return data.smoke
                    ? anyOf([ c.ST_IDLE_SMOKE, c.ST_IDLE_HOBBY, c.ST_IDLE_HOUSING, c.ST_IDLE_PROCRASTINATE ])
                    : anyOf([ c.ST_IDLE_HOBBY, c.ST_IDLE_HOUSING, c.ST_IDLE_PROCRASTINATE ])

            case c.ST_IDLE_PREPARING:
                return c.ST_IDLE_LEAVING

            case c.ST_IDLE_LEAVING:
                data.group = c.ST_AWAY
                ;( data._room.people.length < 2 ) && ( data._room.off() )
                return c.ST_WORK_GOOUT

            case c.ST_WORK_GOOUT:
                return c.ST_WORK_ATWORK

            case c.ST_WORK_ATWORK:
                return c.ST_WORK_ATWORK

            case c.ST_WORK_GOHOME:
                data.group = c.ST_IDLE
                return c.ST_IDLE_ENTERING

            case c.ST_IDLE_ENTERING:
                data._room.on()
                return c.ST_IDLE_COMEHOME

            case c.ST_IDLE_COMEHOME:
                if ( data.hunger > c.LIM_HUNGER ) return c.ST_IDLE_EAT

                if ( data.energy < c.LIM_ENERGY ) {
                    data._room.off()
                    return c.ST_IDLE_GOTOSLEEP
                }

                if ( data.drink && data.balance < 0 ) return c.ST_IDLE_DRINK

                if ( data.drink ) {
                    return data.smoke
                        ? anyOf([ c.ST_IDLE_DRINK, c.ST_IDLE_EAT, c.ST_IDLE_SMOKE, c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                        : anyOf([ c.ST_IDLE_DRINK, c.ST_IDLE_EAT, c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                }

                if ( data._room.aura > .5 ) {
                    return data.smoke
                    ? anyOf([ c.ST_IDLE_EAT, c.ST_IDLE_SMOKE, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                    : anyOf([ c.ST_IDLE_EAT, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                }

                return data.smoke
                    ? anyOf([ c.ST_IDLE_EAT, c.ST_IDLE_SMOKE, c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])
                    : anyOf([ c.ST_IDLE_EAT, c.ST_IDLE_HOUSING, c.ST_IDLE_HOBBY, c.ST_IDLE_PROCRASTINATE ])

            case c.ST_REMOVE_READYTODIE:
                return data.balance < -data.limit
                    ? c.ST_REMOVE_SUICIDE
                    : c.ST_IDLE_HOBBY

            case c.ST_REMOVE_GOTOTRAVEL:
                return data.balance > data.limit
                    ? c.ST_REMOVE_GOAWAY
                    : c.ST_IDLE_HOBBY

            case c.ST_REMOVE_SUICIDE:
                return c.ST_REMOVE_DEAD

            case c.ST_REMOVE_DEAD:
                return c.ST_REMOVE_DEAD

            case c.ST_REMOVE_GOAWAY:
                return c.ST_REMOVE_TRAVEL

            case c.ST_REMOVE_TRAVEL:
                return c.ST_REMOVE_TRAVEL
                
            default:
                console.log( 'No next status:', extract.key( c, data.status, 'ST_' ) )
        }
    }

const historyHas = ( data, key ) => data._history.indexOf( key ) > -1

const updateHistory = ( data, date ) =>
    {
        if ( data._history.length >= 10 ) {
            data._history.shift()
        }

        data._history.push( data.status )
        setTime( data, date )
    }

const formatTime = date => date.unix() / 60

const setTime = ( data, date ) =>
    {
        data._set_time = formatTime( date )
    }

const durationPass = ( data, date, key ) =>
    {
        if ( !data._set_time ) return true

        const
            time = formatTime( date )
            
        return ( time > data._set_time + getDuration( key ) )
    }

const getDuration = key =>
    {
        const
            val = duration[key]

        if ( !val && val !== 0 ) {
            console.error( 'No duration for:', extract.key( c, key, 'ST_' ) )
            return 0
        }

        if ( typeof val === 'number' ) {
            return val
        }

        if ( val.indexOf( '-' ) > 0 ) {
            const
                split = val.split('-'),
                min = parseInt( split[0] ),
                max = parseInt( split[1] ),
                rand = rrandom( max - min ) + min

            return rand
        }

        console.error( 'Strange duration' )
    }

const needWakeUp = ( data, date, light ) =>
    {
        if ( light ) return true 
        
        if ( data.work !== c.WORK_NONE ) {
            if ( workWake( data, date ) ) return true
        }

        if ( data.energy < c.LIM_ENERGY ) return false

        return chronotypeWake( data, date )
    }

const isWeekend = dow => dow === 6 || dow === 7

const workWake = ( data, date ) =>
    {
        const
            dow = date.isoWeekday(),
            day = date.dayOfYear()

        switch ( data.work ) {

            case c.WORK_STANDART:
                if ( isWeekend( dow ) ) return false
                return isWorkHours( data, date )

            case c.WORK_NIGHT:
                if ( isWeekend( dow ) ) return false
                if ( dow === 1 ) return isWorkHours( data, date, c.WORK_NIGHT_1 )
                return isWorkHours( data, date )

            case c.WORK_12:
                switch ( day % 4 ) {
                    case 0:
                    case 3:
                        return false
                    case 2:
                    case 1:
                    default:
                        return isWorkHours( data, date )
                }

            case c.WORK_NOCTIDIAL:
                switch ( day % 3 ) {
                    case 0:
                    default:
                        return false
                    case 1:
                        return isWorkHours( data, date, c.WORK_NOCTIDIAL_1 )
                    case 2:
                        return isWorkHours( data, date, c.WORK_NOCTIDIAL_2 )
                }

            case c.WORK_WATCH:
                switch ( day % 4 ) {
                    case 0:
                    case 3:
                    default:
                        return false
                    case 2:
                        return isWorkHours( data, date, c.WORK_STANDART )
                    case 1:
                        return isWorkHours( data, date, c.WORK_NIGHT )
                }

            case c.WORK_NONE:
            default:
                return false
        }
    }

const workHours = status =>
    {
        switch ( status ) {
            case c.WORK_STANDART:
                return { from: 9, to: 17 }
            case c.WORK_NIGHT:
                return { from: 22, to: 6 }
            case c.WORK_NIGHT_1:
                return { from: 22, to: 30 }
            case c.WORK_12:
                return { from: 8, to: 20 }
            case c.WORK_NOCTIDIAL_1:
                return { from: 8, to: 32 }
            case c.WORK_NOCTIDIAL_2:
                return { from: -16, to: 8 }
            default:
                return { from: 0, to: 0 }
        }
    }

const isWorkHours = ( data, date, worktype ) =>
    {
        const
            hours = parseInt( date.format( 'H' ) ),
            work = worktype || data.work,
            { from, to } = workHours( work ),
            reverse = from > to,
            before = from - 2,
            after = to + 2,
            cond = (
                reverse
                    ? hours >= before || hours <= after
                    : hours >= before && hours <= after
            )

        return cond
    }

const endOfWorkDay = ( data, date ) =>
    {
        const
            hours = parseInt( date.format( 'H' ) ),
            { from, to } = workHours( data.work ),
            reverse = from > to,
            cond = (
                reverse
                    ? hours > to && hours < from
                    : hours > to
            )

        return cond
    }

const chronotypeWake = ( data, date ) =>
    {
        const
            hours = parseInt( date.format( 'H' ) )

        switch ( data.chrono ) {

            case c.CHRONO_OWL:
            default:
                return ( hours > 12 || hours < 4 )

            case c.CHRONO_LARK:
                return ( hours > 5 && hours < 21 )

            case c.CHRONO_DOVE:
                return ( hours > 8 && hours < 24 )
                
            case c.CHRONO_WOODPECKER:
                return ( hours > 6 && hours < 24 )

            case c.CHRONO_SLOTH:
                data._sleep_hour = data._sleep_hour || Math.round( Math.random() * 24 )
                //console.log( data._sleep_hour, data._sleep_hour + 6 )

                return ( hours > data._sleep_hour && hours < data._sleep_hour + 6 )
        }
    }

export const morning = ( data, date ) =>
    {
        const
            smoke = !!data.smoke

        if ( smoke ) return c.ST_IDLE_SMOKE
        return c.ST_IDLE_EAT
    }

export const hobby = data => 10 + data.hobby[ rrandom( data.hobby.length - 1 ) ]