/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { Bitmap } from 'components'
import { extract } from 'tools'
import './balance.scss'

class Balance extends Component {
    static propTypes = {
        date:    PropTypes.object,
        balance: PropTypes.number,
        max:     PropTypes.number,
        deaths:  PropTypes.number,
        trips:   PropTypes.number
    }

    hex = c =>
        {
            const
                res = c.toString(16)

            return res.length < 2
                ? '0' + res
                : res
        }

    color = perc =>
        {
            const
                rperc = perc / 100,
                red = Math.min( 255, Math.round( rperc * 4 * 255 ) ),
                green = red < 255 ? 255 : Math.min( 255, Math.max( 0, 255 - Math.round( ( rperc - .5 ) * 4 * 255 ) ) )

            return '#' + this.hex( red ) + this.hex( green ) + '00'
        }

    time = () => {
        const
            { date } = this.props,
            pre = '⌚',
            hours = extract.hours( date ),
            minutes = extract.minutes( date ),
            text = pre + minutes + hours

        return (
            <Bitmap
                text  = { text }
                title = { date.format( 'HH:mm' ) }
            />
        )
    }

    render () {
        const
            { balance, max } = this.props,
            power = Math.round( Math.abs( balance ) / max * 1000 ) / 10,
            width = power + '%',
            color = this.color( power )

        return (
            <div className="balance-row" title={balance}>
                <div className="balance-count count-deaths">
                    <Bitmap text={ this.props.deaths.toString() } />
                </div>
                <div className="low-balance-icon">
                    <img src="/images/death.png" alt="Death" />
                </div>
                <div className="low-balance-container balance-container">
                    {
                        balance < 0 && (
                            <div className="balance-val balance-low" style={{ width, backgroundColor: color }}></div>
                        )
                    }
                </div>
                <div className="balance-time">
                    { this.time() }
                </div>
                <div className="high-balance-container balance-container">
                    {
                        balance > 0 && (
                            <div className="balance-val balance-hight" style={{ width, backgroundColor: color }}></div>
                        )
                    }
                </div>
                <div className="high-balance-icon">
                    <img src="/images/travel.png" alt="Travel" />
                </div>
                <div className="balance-count count-trips">
                    <Bitmap text={ this.props.trips.toString() } />
                </div>
            </div>
        )
    }
}

export default Balance
