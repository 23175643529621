import * as pers    from './persons'
import * as extract from './extract'

export const set = cmp =>
    {
        const 
            res = {},
            keys = Object.keys( cmp.state )

        keys.forEach( key => {
            res[ key ] = val => cmp.setState({ [key]: val } )
        })

        res.state = data => cmp.setState( data )

        return res
    }

export const load = ( room, saved ) =>
    {
        const
            res = saved.persons.map( p => p )

        res.forEach( pers => pers._room = room )

        return res
    }

export const persons = room => 
    {
        const
            res = pers.template()

        res.forEach( pers => pers._room = room )

        return res
    }

export const rrandom = max => Math.round( Math.random() * max )

export const anyOf = arr =>
    {
        const
            index = rrandom( arr.length - 1 )

        return arr[index]
    }

export const popular = ( chance, pop, other ) =>
    {
        const
            popOrNot = random( chance )

        if ( popOrNot === pop ) return pop

        let
            res = rrandom( other )

        while ( res === pop ) {
            res = rrandom( other )
        }

        return res
    }

export const random = ( chance, cut, string ) => 
    {
        if ( typeof chance !== 'object' ) return rrandom( chance )
        
        if ( !checkChance( chance ) ) {
            console.error( 'Summ is not 100%!', '(' + Object.values( chance ).reduce( ( summ, c ) => summ + c, 0 ) + ')', chance )
            return
        }

        const
            breaks = extract.breakpoints( chance, string ),
            keys = Object.keys( chance ).map( key => string ? key : parseInt( key ) )

        let
            minIndex = 0,
            maxIndex = keys.length - 1
        
        if ( !string ) {
            let
                max = extract.max( keys ),
                min = extract.min( keys )

            if ( cut ) {
                ;( cut < 0 )
                    ? max += cut 
                    : min += cut
            }

            maxIndex = keys.indexOf( max )
            minIndex = keys.indexOf( min )
        }
            
        for ( let i = minIndex; i < maxIndex; i++ ) {
            const
                seed = rrandom( 100 )
                
            for ( let j = maxIndex; j >= minIndex; j-- ) {
                if ( seed >= breaks[keys[j]] ) {
                    return string
                        ? keys[j]
                        : parseInt( keys[j] )
                }
            }
        }
    }

const checkChance = chance => Object.values( chance ).reduce( ( summ, c ) => summ + c, 0 ) === 100