export const APP_VERSION_PRIMARY   = '0'
export const APP_VERSION_SECONDARY = '1'
export const APP_VERSION_BUILD     = '6'
export const APP_VERSION = 'v. ' + APP_VERSION_PRIMARY + '.' + APP_VERSION_SECONDARY + '.' + APP_VERSION_BUILD

export const APP_KEY = 'curtains'

export const KEY_FIRST_RUN  = 'firstRun'
export const KEY_SAVED_GAME = 'saved'
export const KEY_SPEED      = 'speed'
export const KEY_VOLUME     = 'volume'
export const KEY_MUTED      = 'muted'

export const LEVEL_PRIMARY   = 0
export const LEVEL_SECONDARY = 1
export const LEVEL_BUILD     = 2

export const level = key => (
    {
        [KEY_FIRST_RUN]:  LEVEL_BUILD,
        [KEY_SAVED_GAME]: LEVEL_SECONDARY,
        [KEY_SPEED]:      LEVEL_PRIMARY,
        [KEY_VOLUME]:     LEVEL_PRIMARY,
        [KEY_MUTED]:      LEVEL_PRIMARY
    }[key]
)

export const BAR_WIDTH = 100

export const COUNT_WINDOWS  = 5
export const COUNT_CURTAINS = 44
export const COUNT_DECOR    = 46
export const DECOR_NONE     = 0
export const DECOR_OTHER    = 1

export const START_HOUR = 7

export const VAL_HUNGER = 100 / ( 24 * 60 / 2 )
export const VAL_ENERGY = 100 / ( 24 * 60 )
export const VAL_AURA   = 1 / 1000
export const VAL_DEATH  = -.1

export const LIM_HUNGER = 70
export const LIM_ENERGY = 20
export const TOP_ENERGY = 80

export const MAX_BALANCE = 10

export const CH_0  = 'À'
export const CH_1  = 'Á'
export const CH_2  = 'Â'
export const CH_3  = 'Ã'
export const CH_4  = 'Ä'
export const CH_5  = 'Å'
export const CH_6  = 'Ò'
export const CH_7  = 'Ó'
export const CH_8  = 'Ô'
export const CH_9  = 'Õ'
export const CH_10 = 'Ö'
export const CH_11 = 'Ø'
export const CH_12 = 'À'

export const CM_0  = 'à' 
export const CM_5  = 'á' 
export const CM_10 = 'â' 
export const CM_15 = 'ã' 
export const CM_20 = 'ä' 
export const CM_25 = 'å' 
export const CM_30 = 'ò' 
export const CM_35 = 'ó' 
export const CM_40 = 'ô' 
export const CM_45 = 'õ' 
export const CM_50 = 'ö' 
export const CM_55 = 'ø' 
export const CM_60 = 'à' 


export const COUNT_STUDENTS = 5

export const TMPL_STUDENTS = 3
export const TMPL_FAMILY   = 2
export const TMPL_PAIR     = 1
export const TMPL_SINGLE   = 0

export const GEN_MALE   = 1
export const GEN_FEMALE = 0

export const ORIENT_BI     = 2
export const ORIENT_HOMO   = 1
export const ORIENT_HETERO = 0

export const AGE_ELDER  = 3
export const AGE_UPPER  = 2
export const AGE_MIDDLE = 1
export const AGE_YOUNG  = 0

export const CHRONO_OWL        = 0
export const CHRONO_LARK       = 1
export const CHRONO_DOVE       = 2
export const CHRONO_WOODPECKER = 3
export const CHRONO_SLOTH      = 4

export const WORK_NONE        = 0
export const WORK_STANDART    = 1
export const WORK_NIGHT       = 2
export const WORK_12          = 3
export const WORK_NOCTIDIAL   = 4
export const WORK_WATCH       = 5
export const WORK_NIGHT_1     = 6
export const WORK_NOCTIDIAL_1 = 7
export const WORK_NOCTIDIAL_2 = 8

export const HOBBY_STROLL = 0
export const HOBBY_TV     = 1
export const HOBBY_GAMES  = 2
export const HOBBY_READ   = 3
export const HOBBY_CRAFT  = 4

export const ST_IDLE = 0
export const ST_AWAY = 1

export const ST_IDLE_SLEEP         = 0
export const ST_IDLE_WAKEUP        = 1
export const ST_IDLE_GOTOSLEEP     = 2
export const ST_IDLE_EAT           = 3
export const ST_IDLE_SMOKE         = 4
export const ST_IDLE_DRINK         = 5
export const ST_IDLE_HOUSING       = 6
export const ST_IDLE_PROCRASTINATE = 7
//8 ?
export const ST_IDLE_HOBBY        = 9
export const ST_IDLE_HOBBY_STROLL = 10
export const ST_IDLE_HOBBY_TV     = 11
export const ST_IDLE_HOBBY_GAMES  = 12
export const ST_IDLE_HOBBY_READ   = 13
export const ST_IDLE_HOBBY_CRAFT  = 14
//15 for hobbies
//16 for hobbies
//17 for hobbies
//18 for hobbies
//19 for hobbies

export const ST_IDLE_PREPARING = 100
export const ST_IDLE_LEAVING   = 101
export const ST_IDLE_ENTERING  = 102
export const ST_IDLE_COMEHOME  = 103

export const ST_WORK_GOOUT  = 200
export const ST_WORK_ATWORK = 201
export const ST_WORK_GOHOME = 202

export const ST_REMOVE_READYTODIE = 1000
export const ST_REMOVE_GOTOTRAVEL = 1001
export const ST_REMOVE_SUICIDE    = 1002
export const ST_REMOVE_DEAD       = 1003
export const ST_REMOVE_GOAWAY     = 1004
export const ST_REMOVE_TRAVEL     = 1005