/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { classMaker } from 'tools'
import './button.scss'

class Button extends Component {
    static propTypes = {
        active:  PropTypes.bool,
        big:     PropTypes.bool,
        round:   PropTypes.bool,

        icon:    PropTypes.string,
        onClick: PropTypes.func
    }

    constructor ( props ) {
        super( props )
        this.cls = new classMaker( 'game-button' )
    }

    render () {
        const
            { active, icon, round, big, children, onClick, style } = this.props

        ;( this.cls.toggle( active, 'active' ) )
        ;( this.cls.toggle( round, 'round' ) )
        ;( this.cls.toggle( big, 'big' ) )

        return (
            <div className={ this.cls.get()} onClick={ onClick } style={ style }>
                {
                    icon && (
                        <span className="button-icon" style={{ backgroundImage: 'url(' + icon + ')' }}></span>
                    )
                }
                { children }
            </div>
        )
    }
}

export default Button
