export default class Player {
    constructor ( src, loop, volume ) {
        this._audio = document.createElement( 'audio' )
        
        this._audio.src = src
        this._audio.loop = loop
        this._audio.volume = volume || .5
    }
    
    play () {
        this._audio.play()
    }

    pause () {
        this._audio.pause()
    }

    volume ( vol ) {
        this._audio.volume = vol
    }

    toggle ( cb ) {
        ;( this.playing() )
            ? this.pause()
            : this.play()

        cb( !this.playing() )
    }

    playing () {
        return !this._audio.paused
    }
}