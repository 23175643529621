/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { Bitmap, Button } from 'components'
import { classMaker } from 'tools'
import './slider.scss'

class Slider extends Component {
    static propTypes = {
        id:    PropTypes.string,
        label: PropTypes.string,

        min:   PropTypes.number,
        max:   PropTypes.number,
        value: PropTypes.number,

        vertical: PropTypes.bool,
        hidden:   PropTypes.bool,
        button:   PropTypes.bool,

        onChange: PropTypes.func
    }

    constructor ( props ) {
        super( props )
        this.hidden = true
        this.container = React.createRef()
    }

    componentDidMount () {
        document.addEventListener( 'click', e => {
            if ( !this.container.current || !this.container.current.contains( e.target ) ) {
                this.hidden = true
                return
            }
        })
    }

    toggle = () => this.hidden = !this.hidden
    
    render () {
        const
            { id, label, value, min, max, hidden, vertical, button, onChange } = this.props,
            left = value / max * 95 + '%',
            ccls = new classMaker( 'slider-container' ),
            cls  = new classMaker( 'slider-input-container' ),
            cref = this.container

        ;( hidden ) && ( cls.addIf( this.hidden, 'hidden', 'visible' ) )
        ;( vertical ) && ( cls.add( 'vertical' ) )
        ;( vertical ) && ( ccls.add( 'vertical' ) )

        return (
            <div className={ccls.get()} ref={cref}>
                {
                    button
                        ? (
                            <Button active={!this.hidden}>
                                <label htmlFor={id} onClick={this.toggle}>
                                        <Bitmap text={label} />
                                </label>
                            </Button>
                        )
                        : (
                            <label htmlFor={id}>
                                    <Bitmap text={label} />
                            </label>
                        )
                }
                <div className={cls.get()}>
                    <input
                        type  = "range" 
                        id    = { id }
                        value = { value }
                        min   = { min }
                        max   = { max }
                        onChange = { onChange }
                    />
                    <div className="slider-fake">
                        { vertical && ( <div className="slider-fake-tick slider-fake-low"><Bitmap text="-"/></div> ) }
                        <div className="slider-fake-bar"></div>
                        <div className="slider-fake-thumb" style={{ left }}></div>
                        { vertical && ( <div className="slider-fake-tick slider-fake-high"><Bitmap text="+"/></div> ) }
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Slider
