import * as c from './consts'

export default {
    [c.ST_IDLE_SLEEP]:         240,
    [c.ST_IDLE_GOTOSLEEP]:     10,
    [c.ST_IDLE_WAKEUP]:        '5-15',
    [c.ST_IDLE_SMOKE]:         '10-30',
    [c.ST_IDLE_DRINK]:         '15-60',
    [c.ST_IDLE_EAT]:           5,
    [c.ST_IDLE_HOUSING]:      '15-120',
    [c.ST_IDLE_PROCRASTINATE]: '10-60',
    [c.ST_IDLE_HOBBY]:         '5-10',
    
    [c.ST_IDLE_HOBBY_STROLL]: '30-180',
    [c.ST_IDLE_HOBBY_TV]:     '15-120',
    [c.ST_IDLE_HOBBY_GAMES]:  '30-180',
    [c.ST_IDLE_HOBBY_READ]:   '30-120',
    [c.ST_IDLE_HOBBY_CRAFT]:  '60-180',

    [c.ST_IDLE_PREPARING]: '10-30',
    [c.ST_IDLE_LEAVING]:   5,
    [c.ST_WORK_GOOUT]:     '10-60',
    [c.ST_WORK_ATWORK]:    0,
    [c.ST_WORK_GOHOME]:    '10-60',
    [c.ST_IDLE_ENTERING]:  10,
    [c.ST_IDLE_COMEHOME]:  5,

    [c.ST_REMOVE_READYTODIE]: '30-180',
    [c.ST_REMOVE_GOTOTRAVEL]: '60-300',
    [c.ST_REMOVE_SUICIDE   ]: 10,
    [c.ST_REMOVE_DEAD      ]: 0,
    [c.ST_REMOVE_GOAWAY    ]: 15,
    [c.ST_REMOVE_TRAVEL    ]: 0,
}