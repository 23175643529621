import { update } from 'reduxigen'
import moment from 'moment'

import { storage, consts } from 'tools'

const save = ( state, key, val ) =>
    {
        storage.set( key, val )
        return {
            ...state,
            [key]: val
        }
    }

export const updateRoomsCount = update(
    'storage.rooms',
    ( value, state ) => ({
        ...state,
        rooms: value
    })
)

export const updateFloorsCount = update(
    'storage.floors',
    ( value, state ) => ({
        ...state,
        floors: value
    })
)

export const updateDate = update(
    'storage.date',
    ( value, state ) => ({
        ...state,
        date: moment( state.date ).add( state.speed, 'second' )
    })
)

export const setRun = update(
    'storage.run',
    ( run, state) => ({
        ...state,
        run
    })
)

export const setFirstRun = update(
    'storage.firstRun',
    ( firstRun, state ) => save( state, consts.KEY_FIRST_RUN, firstRun )
)

export const setVolume = update(
    'storage.volume',
    ( volume, state ) => save( state, consts.KEY_VOLUME, volume )
)

export const setSpeed = update(
    'storage.speed',
    ( speed, state ) => save( state, consts.KEY_SPEED, speed )
)

export const setMuted = update(
    'storage.muted',
    ( muted, state ) => save( state, consts.KEY_MUTED, muted )
)

export const removeSaved = update(
    'storage.removeSaved',
    ( data, state ) => 
        save(
            state,
            consts.KEY_SAVED_GAME,
            null
        )
)

export const saveGame = update(
    'storage.saved',
    ( data, state ) => 
        save( 
            state, 
            consts.KEY_SAVED_GAME, 
            JSON.stringify({ 
                floors: state.floors, 
                rooms: state.rooms, 
                date: state.date.unix(),
                deaths: data.deaths,
                trips: data.trips,
                house: prepare( data.house ) 
            })
        )
)

const prepare = house =>
    house.map(
        floor => ({
            rooms: floor.ref.current.rooms.map(
                room => {
                    const
                        ref = room.ref.current
                        
                    return {
                        number:   ref.props.number,
                        aura:     ref.aura,
                        light:    ref.light,
                        bgcolor:  ref.bgcolor,
                        window:   ref.window,
                        curtains: ref.curtains,
                        decor:    ref.decor,
                        persons: ref.people.map( person => {
                            const
                                tosave = Object.assign( {}, person )

                            delete tosave._room
                            delete tosave._set_time

                            return tosave
                        })
                    }
                }
            )
        })
    )