export default class classMaker {
    cls = []

    constructor ( def ) {
        this.cls.push( def )
    }

    add ( name ) {
        ;( this.cls.indexOf( name ) < 0 ) && ( this.cls.push( name ) )
    }

    addIf ( cond, success, fail ) {
        ;( cond )
            ? this.add( success )
            : this.add( fail )
    }

    remove ( name ) {
        ;( this.cls.indexOf( name ) > -1 ) && ( this.cls.splice( this.cls.indexOf( name ), 1 ) )
    }

    toggle ( cond, name ) {
        ;( cond )
            ? this.add( name )
            : this.remove( name )
    }

    get () {
        return this.cls.join( ' ' )
    }
}