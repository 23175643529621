/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { classMaker, persons, readable, loop } from 'tools'
import './person.scss'

class Person extends Component {
    static propTypes = {
        data:   PropTypes.object,
        aura:   PropTypes.number,
        light:  PropTypes.bool,
        onAura: PropTypes.func
    }
    
    info = data => 
        (
            Object.keys( persons.readable( data ) )
                .map(
                    key => <p key={persons.key( key )}><b>{persons.key( key )}</b>: {readable[key]( data[key] )}</p>
                )
        )

    update = toggled => 
        {
            const
                { data, aura, date, light, onAura } = this.props

            loop.life( data, onAura )
            loop.status( data, date, light, toggled )
            loop.balance( data, aura )

            return data.balance
        }

    balance = () => this.props.data.balance
    limit   = () => this.props.data.limit

    render () {
        const
            { data } = this.props,
            cls = new classMaker( 'person' ),
            readable = persons.readable( data )

        return (
            <div className={cls.get()}>
                {/*<div className="person-info">
                    {this.info( data )}
                </div>*/}
                {/*[{ readable.gender[0] }] { readable.status || data.status }*/}
                <div 
                    className="person-animation"
                    style={{ 
                        backgroundImage: 'url(/images/animations/' + readable.gender[0].toLowerCase() + '/' + data.status + '.gif)'
                    }}
                />
            </div>
        )
    }
}

export default Person
