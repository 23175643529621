import * as c from './consts'

const pre = level =>
    {
        switch ( level ) {
            case c.LEVEL_BUILD:
                return c.APP_KEY + '_' + c.APP_VERSION_PRIMARY + '.' + c.APP_VERSION_SECONDARY + '.' + c.APP_VERSION_BUILD + '_'
            case c.LEVEL_SECONDARY:
                return c.APP_KEY + '_' + c.APP_VERSION_PRIMARY + '.' + c.APP_VERSION_SECONDARY + '_'
            case c.LEVEL_PRIMARY:
            default:
                return c.APP_KEY + '_' + c.APP_VERSION_PRIMARY + '_'
        }
    }

export const get = ( key, def ) => window.localStorage.getItem( pre( c.level( key ) ) + key ) || def
export const set = ( key, value ) => 
    (
        value === null
            ? window.localStorage.removeItem( pre( c.level( key ) ) + key )
            : window.localStorage.setItem( pre( c.level( key ) ) + key, value )
    )
