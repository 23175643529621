import * as c from './consts'

export const key = key =>
    (
        {
            'group':       'Status group',
            'status':      'Status',
            'balance':     'Happiness',
            'gender':      'Gender',
            'age':         'Age',
            'orientation': 'Orientation',
            'chrono':      'Chronotype',
            'work':        'Work hours',
            'smoke':       'Smoke',
            'drink':       'Drink',
            'hobby':       'Hobby',
            'energy':      'Energy',
            'hunger':      'Hunger',
            'workaholic':  'Like to work',
            'persist':     'Persistance',
            'limit':       'Limit',
            'health':      'Health'
        }[key]
    )

export const balance = key => key
export const limit   = key => key
export const persist = key => Math.round( key * 10 ) / 10000

const perc = key => Math.round( key ) + '%'

export const energy = key => perc( key )
export const hunger = key => perc( key )
export const health = key => perc( key )

export const group = key =>
    (
        {
            [c.ST_IDLE]: 'Idle',
            [c.ST_AWAY]: '(Away from home)'
        }[key]
    )

export const status = key =>
    (
        {
            [c.ST_IDLE_SLEEP]:         'Sleeping',
            [c.ST_IDLE_WAKEUP]:        'Wake up',
            [c.ST_IDLE_GOTOSLEEP]:     'Going to sleep',
            [c.ST_IDLE_EAT]:           'Eating',
            [c.ST_IDLE_SMOKE]:         'Smoking',
            [c.ST_IDLE_DRINK]:         'Drinking',
            [c.ST_IDLE_HOUSING]:      'Housing',
            [c.ST_IDLE_PROCRASTINATE]: 'Doing nothing',

            [c.ST_IDLE_HOBBY]:        'Thinking what to do',
            [c.ST_IDLE_HOBBY_STROLL]: 'Strolling',
            [c.ST_IDLE_HOBBY_TV]:     'Watching TV',
            [c.ST_IDLE_HOBBY_GAMES]:  'Playing games',
            [c.ST_IDLE_HOBBY_READ]:   'Reading',
            [c.ST_IDLE_HOBBY_CRAFT]:  'Crafting',

            [c.ST_IDLE_PREPARING]: 'Preparing for work',
            [c.ST_IDLE_LEAVING]:   'Leaving home',
            [c.ST_IDLE_ENTERING]:  'Entering home',
            [c.ST_IDLE_COMEHOME]:  'Come home',

            [c.ST_WORK_GOOUT]:  'Going to work',
            [c.ST_WORK_ATWORK]: '(At work)',
            [c.ST_WORK_GOHOME]: 'Going home',
            
            [c.ST_REMOVE_READYTODIE]: 'Ready to die',
            [c.ST_REMOVE_GOTOTRAVEL]: 'Ready for adventure',
            [c.ST_REMOVE_SUICIDE   ]: 'Dead :(',
            [c.ST_REMOVE_DEAD      ]: '(Dead)',
            [c.ST_REMOVE_GOAWAY    ]: 'Going for a trip',
            [c.ST_REMOVE_TRAVEL    ]: '(In travel)',
        }[key]
    )

export const work = key =>
    (
        {
            [c.WORK_NONE]:      'No work',
            [c.WORK_STANDART ]: '5/2 8h',
            [c.WORK_NIGHT]:     '5/2 8h (night)',
            [c.WORK_12]:        '2/2 12h',
            [c.WORK_NOCTIDIAL]: '1/2 24h',
            [c.WORK_WATCH]:     '1/1/2 12h'
        }[key]
    )

export const gender = key => 
    (
        {
            [c.GEN_FEMALE]: 'Female',
            [c.GEN_MALE]: 'Male'
        }[key]
    )

export const age = key =>
    (
        {
            [c.AGE_YOUNG]: 'Young',
            [c.AGE_MIDDLE]: 'Middle',
            [c.AGE_UPPER]: 'Upper age',
            [c.AGE_ELDER]: 'Elderly'
        }[key]
    )

export const orientation = key =>
    (
        {
            [c.ORIENT_HETERO]: 'Hetero',
            [c.ORIENT_HOMO]: 'Homo',
            [c.ORIENT_BI]: 'Bi'
        }[key]
    )

export const chrono = key =>
    (
        {
            [c.CHRONO_OWL]:        'Owl',
            [c.CHRONO_LARK]:       'Lark',
            [c.CHRONO_WOODPECKER]: 'Woodpecker',
            [c.CHRONO_SLOTH]:      'Sloth'
        }[key]
    )

const bool = key => 
(
    {
        0: 'No',
        1: 'Yes'
    }[key]
)

export const smoke = key => bool( key )
export const drink = key => bool( key )
export const workaholic = key => bool( key )

export const arrkey = ( data, keys ) => 
    (
        Object.keys( data )
            .filter( key => keys.indexOf( parseInt( key ) ) > -1 )
            .map( key => data[key] )
            .join( ', ' )
    )

export const hobby = keys => arrkey
    (
        {
            [c.HOBBY_STROLL]: 'Stroll',
            [c.HOBBY_TV    ]: 'Watch TV',
            [c.HOBBY_GAMES ]: 'Play Games',
            [c.HOBBY_READ  ]: 'Read books',
        },
        keys
    )