/* VENDOR */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, InputNumber } from 'antd'
import moment from 'moment'

import { 
    Balance, 
    Bitmap, 
    Button, 
    Center, 
    Dialog, 
    Floor, 
    Preload,
    Slider, 
    Splash 
} from 'components'
import { storageActions } from 'services'
import { consts, generate, Player } from 'tools'

import './game.scss'

const 
    { Content } = Layout,
    refs = []

let
    previous = moment().set({ hours: consts.START_HOUR })

class Game extends Component {

    constructor ( props ) {
        super( props )

        this.house = null

        this.pause    = true
        this.gameover = false
        this.credits  = false

        this.deaths = 0
        this.trips  = 0

        this.audio = new Player( '/sound/bg.mp3', true, props.volume / 10 )
    
        this.state = {
            settings: false,
            loading: true
        }

        this.set = generate.set( this )

        this.container = React.createRef()
    }

    generate = props =>
        {
            const
                { floors, rooms, date } = props,
                house = []

            for ( let f = floors; f > 0; f-- ) {
                refs[f] = refs[f] || React.createRef()

                house.push( 
                    <Floor 
                        key    = { f }
                        number = { f }
                        last   = { f === floors }
                        rooms  = { rooms }
                        date   = { date }
                        ref    = { refs[f] }
                        onDie  = { this.onDeath }
                        onTrip = { this.onTrip }
                    /> 
                )
            }

            return house
        }

    load = props =>
        {
            const
                { floors, rooms, date, saved } = props,
                house = []

            for ( let f = floors; f > 0; f-- ) {
                refs[f] = refs[f] || React.createRef()

                house.push( 
                    <Floor 
                        key    = { f }
                        number = { f }
                        last   = { f === floors }
                        rooms  = { rooms }
                        saved  = { saved.house[floors - f] }
                        date   = { date }
                        ref    = { refs[f] }
                        onDie  = { this.onDeath }
                        onTrip = { this.onTrip }
                    /> 
                )
            }

            return house
        }

    componentDidMount () {
        requestAnimationFrame( this.gameLoop )
    }

    componentWillReceiveProps ( nextProps ) {
        const
            { date } = this.props

        ;( date !== nextProps.date ) && ( this.rebuild( nextProps ) )
    }

    rebuild = props => this.house = this.generate( props )

    music = () => ( ( !this.props.muted ) && ( this.audio.play() ) )

    newGame = () => 
        {
            this.deaths = 0
            this.trips = 0

            this.gameover = false
            this.credits = false

            this.set.settings( true )
            this.music()
        }

    resume = () =>
        {
            const
                { date, saved, setRun } = this.props

            previous = moment( date )

            this.house = this.load( this.props )
            this.deaths = saved.deaths
            this.trips = saved.trips

            setRun( false )
            this.pause = false
            this.music()
        }

    start = () =>
        {
            this.house = this.generate( this.props )
            this.props.setRun( false )
            this.pause = false
            this.set.settings( false )
        }
    
    gameLoop = () => 
        {
            if ( this.pause ) {
                this.forceUpdate()
                requestAnimationFrame( this.gameLoop )
                return
            }

            const
                { date, updateDate } = this.props,
                passes = Math.floor( ( date.unix() - previous.unix() ) / 60 ),
                realAura = this.house.reduce( 
                    ( summ, floor ) => floor.ref.current 
                        ? summ + floor.ref.current.realAura() 
                        : summ, 
                    0 
                ) / this.house.length,
                count = this.house.reduce( 
                    ( summ, floor ) => floor.ref.current
                        ? summ + floor.ref.current.count()
                        : summ, 
                    0 
                )

            if ( realAura === -1 && count === 0 ) {
                updateDate()
                this.update()
                this.gameover = true
                this.pause = true
                this.props.removeSaved()
                requestAnimationFrame( this.gameLoop )
            }

            updateDate()
            
            if ( passes < 1 ) {
                requestAnimationFrame( this.gameLoop )
                return
            }

            for ( let i = 0; i < passes; i++ ) {
                this.update()
            }
            
            this.props.saveGame({
                house:  this.house,
                deaths: this.deaths,
                trips:  this.trips
            })
            previous = moment( date )
            requestAnimationFrame( this.gameLoop )
        }

    update = () => 
        {
            const
                globalAura = this.house.reduce( 
                    ( summ, floor ) => floor.ref.current
                        ? summ + floor.ref.current.aura()
                        : summ, 
                    0 
                ) / this.house.length

            this.house.forEach( floor => floor.ref.current &&  floor.ref.current.update( globalAura ) )
        }

    onDeath = room =>
        {
            this.deaths++
            this.house.forEach( floor => floor.ref.current.death( room ) )
        }

    onTrip = room =>
        {
            this.trips++
        }

    max = () => 
        this.house.reduce( 
            ( total, floor ) => 
                (
                    floor.ref.current
                        ? total + floor.ref.current.max()
                        : total
                ),
                0
        )

    balance = () => 
        {
            const
                max = this.max()

            return Math.max(
                -max,
                Math.min(
                    max,
                    this.house.reduce( 
                        ( summ, floor ) => 
                            (
                                floor.ref.current 
                                    ? summ + floor.ref.current.balance()
                                    : summ
                            ), 
                            0
                    )
                )
            )
        }

    setSpeed    = e  => this.props.setSpeed( parseInt( e.target.value ) )
    onFirstRun  = () => this.props.setFirstRun( false )
    toggleSound = () => this.audio.toggle( this.props.setMuted )
    menu        = () => this.pause = true
    exitMenu    = () => this.pause = false
    showCredits = () => this.credits = true
    hideCredits = () => this.credits = false
    onLoad      = () => this.set.loading( false )

    setVolume   = e => 
        {
            const
                val = parseInt( e.target.value )

            this.props.setVolume( val )
            this.audio.volume( val / 10 )
        }

    scrollHeight = () => ( this.container.current && this.container.current.scrollHeight - 1 )

    settings = () =>
        (
            <Center>
                <Dialog
                    footer = {
                        <Button big onClick={this.start}>
                            <Bitmap text="Start" />
                        </Button>
                    }
                >
                    <Row>
                        <Col span={16}>
                            <Bitmap text="Rooms per floor" />
                        </Col>
                        <Col span={8}>
                            <InputNumber
                                min = { 1 }
                                max = { 4 }
                                value    = { this.props.rooms } 
                                onChange = { this.props.updateRoomsCount }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>
                            <Bitmap text="Floors" />
                        </Col>
                        <Col span={8}>
                            <InputNumber
                                min = { 1 }
                                max = { 10 }
                                value    = { this.props.floors } 
                                onChange = { this.props.updateFloorsCount }
                            />
                        </Col>
                    </Row>
                </Dialog>
            </Center>
        )
        
    welcome = () => 
        (
            <Center>
                <img
                    src = "/images/logo.png"
                    alt = "Through the Curtains"
                    className = "logo"
                />
                {
                    this.props.saved
                        ? (
                            <Button big onClick={this.resume}>
                                <Bitmap text="Continue" />
                            </Button>
                        )
                        : (
                            <Button big onClick={this.newGame}>
                                <Bitmap text="New Game" />
                            </Button>
                        )
                }
                <div className="app-version">{ consts.APP_VERSION }</div>
            </Center>
        )

    creditsDlg = () =>
        (
            <Dialog
                headerBG = '#000'
                header = {
                    <img 
                        src = "/images/somberkids.png" 
                        alt = "Somberkids" 
                        className = "dialog-full-logo"
                    />
                }
                footer = {
                    <Button onClick={this.hideCredits}>
                        <Bitmap text="Back" />
                    </Button>
                }
            >
                <div><Bitmap font="dark" text="Idea, code, music" /></div>  
                <div>
                    <a href="http://aturbidflow.art" target="_blank">
                        <Bitmap text="aturbidflow" />
                    </a>
                </div>
                <br/>
                <div><Bitmap font="dark" text="Graphics" /></div>    
                <div>
                    <a href="https://twitter.com/veksell" target="_blank">
                        <Bitmap text="Veksell" />
                    </a>
                </div>   
            </Dialog>
        )
    
    menuDlg = () => 
        (
            <Dialog
                header = {
                    <img 
                        src = "/images/logo-small.png" 
                        alt = "Through the Curtains" 
                        className = "dialog-full-logo"
                    />
                }
                footer = {
                    <React.Fragment>
                        <Button onClick={this.newGame} style={{ float: 'left' }}>
                            <Bitmap font="red" text="Reset game" />
                        </Button>
                        <Button onClick={this.exitMenu}>
                            <Bitmap text="Close" />
                        </Button>
                    </React.Fragment>
                }
            >
                <br/>
                <Slider
                    label = "Music"
                    min   = {1}
                    max   = {10}
                    value = { this.props.volume }
                    onChange = { this.setVolume }
                />
                <br/>
                <Button onClick={this.showCredits}>
                    <Bitmap text="Credits" />
                </Button>
            </Dialog>
        )
    
    gameoverDlg = () => 
        (
            <Dialog
                header = {
                    <Center>
                        <Bitmap font="dark" text="GAMEOVER" />
                    </Center>
                }
                footer = {
                    <Button onClick={this.newGame}>
                        <Bitmap text="Restart" />
                    </Button>
                }
            >
            <div>
                <Bitmap font="dark" text="Total trips: " />
                <Bitmap text={this.trips.toString()} />
            </div>
            <div>
                <Bitmap font="dark" text="Total deaths: " />
                <Bitmap text={this.deaths.toString()} />
            </div>
            </Dialog>
        )
    
    game = () =>
        (
            <div className="game-content">
                <div className="top-controls">
                    <div className="controls">
                        <Button
                            round
                            active  = { this.pause }
                            icon    = '/images/menu.png' 
                            onClick = { this.menu }
                        />
                    </div>
                    <div className="balance">
                        <Balance 
                            balance = { Math.round( this.balance() * 10 ) / 10 }
                            max     = { this.max() }
                            date    = { this.props.date }
                            deaths  = { this.deaths }
                            trips   = { this.trips }
                        />
                    </div>
                    <div className="speed">
                        <Button
                            round
                            active  = { !this.audio.playing() }
                            icon    = '/images/sound.png' 
                            onClick = { this.toggleSound } 
                        />
                        <Slider
                            vertical
                            hidden
                            button
                            id    = "speed"
                            label = { window.innerWidth <= 640 ? '»' : 'Speed' }
                            value = { this.props.speed }
                            min   = { 1 }
                            max   = { 30 }
                            onChange = { this.setSpeed }
                        />
                    </div>
                </div>
                <div className="house-container" ref={this.container}>
                    <div className="city-background" style={{ height: this.scrollHeight() }}>
                        <img className="background" src="/images/acity3.png" />
                    </div>
                    <div className="house">
                        { this.house }
                    </div>
                </div>
                {
                    this.pause && (
                        <div className="pause-overlay">
                            <Center>
                                {
                                    this.credits
                                        ? this.creditsDlg()
                                        : (
                                            this.gameover
                                                ? this.gameoverDlg()
                                                : this.menuDlg()
                                        )
                                }
                            </Center>
                        </div>
                    )
                }
            </div>
        )

    render () {
        const
            { firstRun, run } = this.props,
            { settings, loading } = this.state

        return (
            <section className="app-page app-page-game">
                <Layout>
                    <Content>
                        <Splash
                            first = { firstRun }
                            onEnd = { this.onFirstRun }
                        />
                        <Preload
                            onLoad = { this.onLoad }
                        />
                        {
                            !loading && (
                                settings 
                                    ? this.settings()
                                    : (
                                        run
                                            ? this.welcome()
                                            : this.game()
                                    )
                            )
                        }
                    </Content>
                </Layout>
            </section>
        )
    }
}

const mapStateToProps = state => state

export default connect( mapStateToProps, storageActions )( Game )
