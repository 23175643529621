import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'

import { Game } from 'pages'

import './app.scss'
import './animations.scss'

class App extends Component {
    render () {
        return (
            <div className="App">
                <Switch>
                    <Route exact path='/' component={Game} />
                </Switch>
            </div>
        )
    }
}

export default withRouter( App )
