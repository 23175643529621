/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { classMaker, consts as c } from 'tools'
import './bitmap.scss'

const
    width = 16,
    chars = [ 
        ' ', '!', '"', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', 
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', 
        '@', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 
        'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '[', '\\', ']', '^', '_', 
        '`', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 
        'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '{', '|', '}', '~', '•', 
        'А', "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И", "Й", "К", "Л", "М", "Н", "О", 
        "П", "Р", "С", "Т", "У", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Ъ", "Ы", "Ь", "Э", "Ю", 
        "Я", "№", '↓', '↡', '↑', '↟', '«', '»', '♥', '♦', '', '', '', '', '', '', 
        'а', "б", "в", "г", "д", "е", "ё", "ж", "з", "и", "й", "к", "л", "м", "н", "о", 
        "п", "р", "с", "т", "у", "ф", "х", "ц", "ч", "ш", "щ", "ъ", "ы", "ь", "э", "ю", 
        "я", '⌚', c.CH_0, c.CH_1, c.CH_2, c.CH_3, c.CH_4, c.CH_5, c.CH_6, c.CH_7, c.CH_8, c.CH_9, c.CH_10, c.CH_11, '', '',
        c.CM_0, c.CM_5, c.CM_10, c.CM_15, c.CM_20, c.CM_25, c.CM_30, c.CM_35, c.CM_40, c.CM_45, c.CM_50, c.CM_55
    ]

class Bitmap extends Component {
    static propTypes = {
        font:  PropTypes.string,
        text:  PropTypes.string,
        title: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
    }

    text = () => this.props.text.split( ' ' ).map( this.word )
    code = char => chars.indexOf(char)
    row  = char => Math.floor( this.code( char ) / width ) 
    col  = char => this.code( char ) % width

    word = ( word, index, arr ) => 
        (
            <React.Fragment key={index}>
                <span className="word" key={index}>{ word.split( '' ).map( this.letter ) }</span>
                { ( index < arr.length - 1 ) && ( this.letter( ' ', -index ) ) }
            </React.Fragment>
        )

    letter = ( char, index ) =>
        {
            const
                code = this.code( char ),
                key  = index + '-' + code,
                cls  = new classMaker( 'letter' )

            cls.add( 'l-row-'  + this.row( char ) )
            cls.add( 'l-col-'  + this.col( char ) )
            cls.add( 'l-code-' + code )

            return (
                <span className={cls.get()} key={key}></span>
            )
        }

    render () {
        const
            { text, title, font } = this.props,
            cls = new classMaker( 'bitmap-font' )

        ;( font ) && ( cls.add( 'font-' + font ) )

        return (
            <div className={cls.get()} title={title || text}>
                { this.text() }
            </div>
        )
    }
}

export default Bitmap
