/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { Center } from 'components'
import { classMaker } from 'tools'
import './splash.scss'

class Splash extends Component {
    static propTypes = {
        first: PropTypes.bool,
        onEnd: PropTypes.func
    }

    constructor ( props ) {
        super( props )
        this.state = {
            hidden: !props.first
        }
    }

    componentDidMount() {
        setTimeout( this.disappear, 2000 )
    }

    disappear = () => 
        {
            this.setState({ hidden: true })
            this.props.onEnd()
        }

    render () {
        const
            cls = new classMaker( 'splash' )

        cls.addIf( this.state.hidden, 'hidden', true )

        return (
            <div className={cls.get()}>
                <Center>
                    <img src="/images/somberkids.png" alt="Somberkids" />
                </Center>
            </div>
        )
    }
}

export default Splash
