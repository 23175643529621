import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from 'reduxigen'
import moment from 'moment'

import { consts, storage } from 'tools'

const 
    saved = storage.get( consts.KEY_SAVED_GAME, false ),
    parsed = saved ? JSON.parse( saved ) : false,
    date = saved ? moment.unix( parsed.date ) : moment().set({ hours: consts.START_HOUR })

export const initialState = {
    rooms: parsed ? parsed.rooms : 2,
    floors: parsed ? parsed.floors : 3,
    date,

    speed: parseInt( storage.get( consts.KEY_SPEED, 5 ) ),
    volume: parseInt( storage.get( consts.KEY_VOLUME, 5 ) ),
    muted: storage.get( consts.KEY_MUTED ) === 'true',

    firstRun: !storage.get( consts.KEY_FIRST_RUN ),
    run: true,
    saved: parsed ? parsed : null
}

export default createStore(
    rootReducer( initialState ),
    applyMiddleware( thunk )
)
