import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Center } from 'components'
import { classMaker, consts, generate } from 'tools'
import './preload.scss'

const
    images = [
        '/fonts/big.png',
        '/fonts/dark.png',
        '/fonts/red.png',

        '/images/bad-aura.png',
        '/images/good-aura.png',
        
        '/images/logo.png',
        '/images/logo-small.png',
        '/images/somberkids.png',
        '/images/somberkids-trans.png',

        '/images/death.png',
        '/images/travel.png',

        '/images/menu.png',
        '/images/sound.png',

        '/images/dialog.png'
    ]

//Walls
for ( let i = 1; i <= 9; i++ ) {
    images.push( '/images/walls/' + i + '.png' )
}

//Windows
for ( let i = 1; i <= consts.COUNT_WINDOWS; i++ ) {
    images.push( '/images/windows/' + i + '.png' )
}

//Curtains
for ( let i = 1; i <= consts.COUNT_CURTAINS; i++ ) {
    images.push( '/images/curtains/' + i + '.png' )
}

//Decor
for ( let i = 1; i <= consts.COUNT_DECOR; i++ ) {
    images.push( '/images/decor/' + i + '.png' )
}

//Animations
const
    availableKeys = Object.keys( consts ).filter( 
        key => key.indexOf( 'ST_' ) === 0 && key !== 'ST_IDLE' && key !== 'ST_AWAY' 
    )

availableKeys.forEach( key => {
    images.push( '/images/animations/m/' + consts[key] + '.gif' )
})

class Preload extends Component {
    static propTypes = {
        onLoad: PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            loaded: false,
            total: images.length,
            done: 0
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        this.run()
    }

    run = () => images.forEach( this.load )

    load = image => 
        {
            const
                img = document.createElement( 'img' ),
                div = document.createElement( 'div' )

            img.onload = this.done( image, div )
            img.onerror = this.error( image, div )
            img.src = image

            div.className = 'hidden-css-load'
            div.style.backgroundImage = 'url(' + image + ')'
            document.body.appendChild( div )
        }

    error = ( url, div ) =>
        {
            return () =>
                {
                    console.error( 'Error loading "' + url + '"' )
                    this.done( url, div )()
                }
        }

    done = ( url, div ) =>
        {
            return () =>
                {
                    const
                        { done, total } = this.state,
                        loaded = this.state.done + 1

                    //document.body.removeChild( div )
                    
                    ;( loaded === total ) && ( this.onDone() )
                    this.set.done( loaded )
                }
        }

    percents = () => consts.BAR_WIDTH / 2 + this.state.done / this.state.total * ( consts.BAR_WIDTH / 2 ) + 'px'

    onDone = () => 
        {
            this.set.loaded( true )
            setTimeout( this.props.onLoad, 500 )
        }

    render () {
        const
            { loaded } = this.state,
            cls = new classMaker( 'loader' )

        cls.toggle( loaded, 'loaded' )
        
        return (
            <div className={cls.get()}>
                <Center>
                    <div id="cssload-loader">
                        <div className="cssload-diamond"></div>
                        <div className="cssload-diamond"></div>
                        <div className="cssload-diamond"></div>
                    </div>
                    <br/>
                    <span className="load-text">LOADING</span>
                    <div className="load-bar">
                        <div style={{ width: this.percents() }}></div>
                    </div>
                </Center>
            </div>
        )
    }
}

export default Preload
