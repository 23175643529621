import { random, rrandom } from './generate'

import * as c      from './consts'
import * as chance from './chance'
import * as texts  from './readable'

export const template = () =>
    {
        const
            seed = random( chance.template )

        switch ( seed ) {
            case c.TMPL_STUDENTS:
                //console.log( 'students' )
                return students()
            case c.TMPL_FAMILY:
                //console.log( 'family' )
                return family()
            case c.TMPL_PAIR:
                //console.log( 'pair' )
                return pair()
            case c.TMPL_SINGLE:
            default:
                //console.log( 'single' )
                return single()
        }
    }

const students = () =>
    {
        const
            count = random( chance.students ) + 1, //1 to 5
            gender = random( chance.gender ),
            res = []
            
        for ( let i = 0; i < count; i++ ) {
            res.push( person({ gender }) )
        }

        return res
    }

const family = () =>
    {
        const
            age = random( chance.age, -2 ),
            gender = random( chance.gender )
        
        return [
            person({ gender: c.GEN_FEMALE, age: age + 2, orientation: c.ORIENT_HETERO }),
            person({ gender: c.GEN_MALE, age: age + 2, orientation: c.ORIENT_HETERO }),
            person({ gender, age })
        ]
    }

const pair = () =>
    {
        const
            age = random( chance.age )

        return [
            person({ gender: c.GEN_FEMALE, age, orientation: c.ORIENT_HETERO }),
            person({ gender: c.GEN_MALE, age, orientation: c.ORIENT_HETERO })
        ]
    }

const single = () => [person()]

const person = predef =>
    {
        const
            rnd = personGen()

        return {
            ...rnd,
            ...predef
        }
    }

const personGen = () =>
    {
        const
            pers ={}

        pers.gender      = random( chance.gender, 0 )
        pers.orientation = orient( pers.gender )
        pers.age         = random( chance.age )
        pers.chrono      = random( chance.chrono )
        pers.work        = hasWork( pers.gender, pers.age )
        pers.hobby       = hobby()

        pers.group  = 0
        pers.status = 0

        pers.smoke = habits( pers.gender, pers.age, 'smoke' )
        pers.drink = habits( pers.gender, pers.age, 'drink' )
        pers.workaholic = random( chance.workaholic )

        pers.balance = random( chance.balance ) - c.MAX_BALANCE / 4
        pers.persist = rrandom( 1000 ) * 2 + 500
        pers.limit   = Math.min( 9.5, Math.round( rrandom( c.MAX_BALANCE ) + c.MAX_BALANCE ) / 2 )

        pers.energy = 100
        pers.hunger = 0
        pers.health = rrandom( 50 ) + 50

        pers._history = []

        return pers
    }

const hobby = () =>
    {
        const
            count = random( chance.hobbyCount ),
            res = []

        let
            h = random( chance.hobby )

        for ( let i = 0; i < count; i++ ) {
            while ( res.indexOf( h ) > -1 ) {
                h = random( chance.hobby )
            }
            res.push( h )
        }

        return res
    }

const habits = ( gender, age, habit ) =>
    (
        gender === c.GEN_MALE
            ? random( chance[habit][age] )
            : random( chance[ 'f'+habit ][age] )
    )

const hasWork = ( gender, age ) =>
    {
        if ( age === c.AGE_ELDER ) {
            return random( chance.pension )
        }

        return gender === c.GEN_MALE
            ? random( chance.work )
            : random( chance.fwork )
    }

const orient = gender =>
    (
        gender === c.GEN_MALE
            ? random( chance.orientation )
            : random( chance.forientation )
    )

export const readable = data => 
    {
        const
            res = {}

        Object.keys( data ).forEach(
            key => {
                if ( key[0] !== '_' ) {
                    if ( !texts[key] ) {
                        console.error( 'No readable format for "' + key + '"')
                        return
                    }
                    
                    res[key] = texts[key]( data[key] )
                }
            }
        )

        return res
    }

export const key = key => texts.key( key )